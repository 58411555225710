'use client'
import { Dialog, DialogPanel } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'

import clsxm from '@/lib/clsxm'
import { delay, getActivityProps } from '@/lib/helper'
import { useTracking } from '@/hooks/useTracking'
import { useUser } from '@/hooks/useUser'

import NextImage from '@/components/NextImage'

import AppStoreIcon from '@/assets/icons/appstore-icon.png'
import CloseBtnImage from '@/assets/icons/close-search.png'
// import * as utils from '@app/lib/utils'
// import { userService } from '@app/services'
import PlayStoreIcon from '@/assets/icons/playstore-icon.png'
import ThreeCreditsImage from '@/assets/icons/three-credits.png'
import DownloadApppQrImage from '@/assets/modal-images/download-appp-qr.jpg'
import FreeSubscriptionActivitiesImage from '@/assets/modal-images/free-subscription-activities.png'
import SuccessImage from '@/assets/modal-images/success.png'
import { api } from '@/services/api'

import Button from './buttons/Button'
import WebLink from './links/WebLink'
import Loader from './Loader'
import { withNoSSR } from './withNoSSR'

import { ActivityDate, User } from '@/types'

type TrialRequired = {
  type: 'trial-required'
  meta: { activityDate: ActivityDate }
  handleCloseModal: CallableFunction
}

type OutCreditsGenericPopUp = {
  type: 'out-credits-generic-pop-up'
  handleCloseModal: CallableFunction
}

type ThreeFreeInviteCredits = {
  type: '3-free-invite-credits'
  meta: { notificationId: string }
  handleCloseModal: CallableFunction
}

type DownloadAppPopUp = {
  type: 'download-app-pop-up'
  handleCloseModal: CallableFunction
}

type FreeSubscriptionActivities = {
  type: 'free-subscription-activities'
  meta: { freeSubscriptionActivitiesBalance: number }
  handleCloseModal: CallableFunction
}

export type ImageModalProps =
  | TrialRequired
  | OutCreditsGenericPopUp
  | ThreeFreeInviteCredits
  | DownloadAppPopUp
  | FreeSubscriptionActivities

export const ModalCloseBtn = ({ className, onClose }: { onClose: CallableFunction; className: string }) => {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      role="dialog"
      onClick={() => onClose()}
      className={clsxm(
        'border-error-primary flex flex-row items-center justify-center rounded-[20px] border px-[14px] py-[7px]',
        'focus:outline-error-primary  focus:ring-0',
        className,
      )}>
      <span className="text-error-primary text-base font-medium leading-none">{t(`Close`)}</span>
      <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_687_16438)">
          <line
            x1="5.0314"
            y1="5.58105"
            x2="12.1499"
            y2="12.6996"
            stroke="#FF0000"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="4.84766"
            y1="12.5272"
            x2="11.9662"
            y2="5.40868"
            stroke="#FF0000"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_687_16438">
            <rect width="10" height="10.3525" fill="white" transform="translate(3.5 3.76123)" />
          </clipPath>
        </defs>
      </svg>
    </button>
  )
}

const TrialRequiredModal = ({ meta, handleCloseModal }: TrialRequired) => {
  const router = useRouter()
  const { t, lang } = useTranslation()
  const { user, login } = useUser()
  const tracking = useTracking()

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const { activityDate } = meta

  const handleStartTrialAndBook = async () => {
    try {
      setIsBusy(true)
      const trailRes = await api.startTrial()

      if (trailRes.kind !== 'ok') {
        console.log(`startTrial errror `, trailRes.error)
        setIsBusy(false)

        setError(trailRes.error?.message || 'Error')
        return
      }

      await delay(6000)

      const res = await api.me<User | { data: User }>()
      if (res.kind === 'ok') {
        const userInfo = 'data' in res.data ? res.data.data : res.data
        login(userInfo)

        // const successRouteInfo = {
        //   pathname: '/activity/booking-summary',
        //   query: {
        //     id: activityDate.activity.id,
        //     sessionDate: activityDate.sessionDate,
        //     time: activityDate.sessionTime,
        //     sessionId: activityDate.id,
        //     bookingAvailable: true,
        //   },
        // }

        // utils.track('Date Selected', {
        //   date: activityDate.sessionDate,
        //   activity: activityDate.activity,
        // })
        if (activityDate.activity) {
          const activityEvtProps = getActivityProps('Activity Viewed', activityDate.activity)
          tracking.track({ evt: 'Date Selected', data: { ...activityEvtProps, date: activityDate.sessionDatetime } })

          const bookingAvailable = parseInt(activityDate.maxUsers) - parseInt(activityDate.totalBooked)

          const successRouteParams = new URLSearchParams()
          successRouteParams.set('id', `${activityDate.activity.id}`)
          successRouteParams.set('sessionDate', activityDate.sessionDate)
          successRouteParams.set('time', activityDate.sessionTime)
          successRouteParams.set('sessionId', `${activityDate.id}`)
          successRouteParams.set('bookingAvailable', `${bookingAvailable}`)

          const successRoute = `/${lang}/activities/booking-summary?${successRouteParams.toString()}`

          router.push(successRoute)
        }
      }
    } catch (e) {
      console.log(`startTrial errror `, e)
      setError(e?.message || 'Error')
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <div className="fixed inset-0 overflow-x-auto align-middle shadow-xl transition-all md:flex md:items-center md:justify-center">
      <Dialog.Panel className="bg-background-10 max-w-[460px] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
        <div className="relative flex flex-col items-center justify-center bg-[#E0EDEF] px-5 pb-8 pt-3">
          <button className="self-end" onClick={() => handleCloseModal()}>
            <NextImage src={CloseBtnImage} alt="close icon" />
          </button>
          <NextImage src={SuccessImage} width={168} height={122} alt="join-trial" />
        </div>
        <div className="px-8 pb-8 pt-4">
          <p className="font-roboto text-text-800 mb-4 text-center text-3xl font-medium">{t(`Congratulations!`)}</p>

          <p className="font-roboto text-text-800 mb-4 text-center text-lg">
            {t(`You now have 3 credits to enjoy 3 activities`)}
          </p>

          {isBusy && (
            <div className="flex h-24 items-center justify-center">
              <Loader />
            </div>
          )}

          <button
            disabled={isBusy}
            className="bg-primary-800 from-primary-400 hover:bg-primary-900 w-full rounded-2xl bg-gradient-to-b px-6 py-4 text-center text-2xl font-semibold tracking-widest text-white shadow-lg transition-colors duration-300 disabled:opacity-50"
            onClick={handleStartTrialAndBook}>
            {t('Book an Activity')}
          </button>
        </div>
      </Dialog.Panel>
    </div>
  )
}

const OutCreditsGenericPopUp = ({ handleCloseModal }: OutCreditsGenericPopUp) => {
  const { t, lang } = useTranslation()

  //   const { activityDate } = meta

  const tracking = useTracking()

  const handlePlayStoreClick = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })
    window.open('https://play.google.com/store/apps/details?id=com.vermutapp.mobile')
  }

  const handleAppStoreClick = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })
    window.open('https://apps.apple.com/us/app/vermut/id1591939009')
  }

  return (
    <div className="fixed inset-0 overflow-x-auto align-middle shadow-xl transition-all md:flex md:items-center md:justify-center">
      <Dialog.Panel className="bg-background-10 max-w-[700px] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
        <div className="relative flex flex-col items-center justify-center bg-[#E0EDEF] px-5 pb-8 pt-3">
          <ModalCloseBtn className="self-end" onClose={handleCloseModal} />
          <NextImage src={SuccessImage} width={168} height={122} alt="join-trial" />
        </div>
        <div className="px-8 pb-8 pt-4">
          <p className="font-roboto text-text-800 mb-4 text-center text-3xl font-medium">
            {t(`Enjoy this functionality and many more in the app completely free!`)}
          </p>

          <p className="font-roboto text-text-800 mb-4 text-center text-lg">
            {t(
              `Connect with people with the same interests, find activities on the calendar, create Meetups with your friends, join chat groups, manage your reservations and much more.`,
            )}
          </p>
          <div className="flex flex-col justify-between md:flex-row">
            <button
              className="bg-primary-800 from-primary-400 hover:bg-primary-900 mb-4 flex items-center rounded-2xl bg-gradient-to-b px-2 py-3 text-center text-lg font-semibold tracking-widest text-white shadow-lg transition-colors duration-300 disabled:opacity-50 md:mb-0"
              onClick={handlePlayStoreClick}>
              {/* <CustomeImage src={PlayStoreIconImage} width={24} height={24} alt="play-store-icon" /> */}
              {t('Download on Google Play')}
            </button>

            <button
              className="bg-primary-800 from-primary-400 hover:bg-primary-900 flex items-center rounded-2xl bg-gradient-to-b px-2 py-3 text-center text-lg font-semibold tracking-widest text-white shadow-lg transition-colors duration-300 disabled:opacity-50"
              onClick={handleAppStoreClick}>
              {/* <CustomeImage src={AppStoreIconImage} width={24} height={24} alt="app-store-icon" /> */}
              {t('Download on App Store')}
            </button>
          </div>

          <div className="mt-4 flex items-center justify-center">
            <Link href={`/${lang}/contact`} locale={lang}>
              <span className="font-roboto text-secondary-400 text-lg font-medium underline">
                {t(`You have doubts? contact us`)}
              </span>
            </Link>
          </div>
        </div>
      </Dialog.Panel>
    </div>
  )
}

const ThreeFreeInviteCreditsModal = ({ meta, handleCloseModal }: ThreeFreeInviteCredits) => {
  const { t } = useTranslation()
  const { markNotificationAsRead } = useUser()
  const tracking = useTracking()

  const { notificationId } = meta

  React.useEffect(() => {
    if (notificationId) {
      // debugger
      markNotificationAsRead(notificationId)
    }
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-x-auto align-middle shadow-xl transition-all">
      <Dialog.Panel className="bg-background-10 max-w-[475px] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
        <div className="relative flex flex-col items-center justify-center bg-[#E0EDEF] px-5 pb-8 pt-3">
          <ModalCloseBtn className="self-end" onClose={handleCloseModal} />
          <NextImage src={ThreeCreditsImage} width={240} height={135} alt="three free invite credits" />
        </div>
        <div className="px-8 pb-8 pt-4">
          <p className="font-roboto text-text-800 mb-4 text-center text-[28px] font-medium">
            {t(`three_free_invite_credits_modal_title`)}
          </p>

          <p className="font-roboto text-text-800 mb-4 text-center text-lg">
            {t(`three_free_invite_credits_modal_para`)}
          </p>
          <div className="flex items-center justify-center">
            <hr className="mb-4 w-2/3 self-center" />
          </div>
          <p className="font-roboto text-text-800 mb-4 text-center text-lg">
            {t(`three_free_invite_credits_modal_para_2`)}
          </p>
          <div className="flex flex-col justify-between md:flex-row">
            <button
              // disabled={isBusy}
              className="bg-primary-800 from-primary-400 hover:bg-primary-900 w-full rounded-2xl bg-gradient-to-b px-6 py-4 text-center text-2xl font-semibold tracking-widest text-white shadow-lg transition-colors duration-300 disabled:opacity-50"
              onClick={() => handleCloseModal()}>
              {t('three_free_invite_credits_modal_parimary_cta')}
            </button>
          </div>
        </div>
      </Dialog.Panel>
    </div>
  )
}

const DownloadAppModal = ({ handleCloseModal }: DownloadAppPopUp) => {
  const { t, lang } = useTranslation()

  //   const { activityDate } = meta

  const tracking = useTracking()

  const checkMobile = () => {
    const u = navigator.userAgent
    return !!u.match(/Android/i) || !!u.match(/iPhone/i)
  }

  const handlePlayStoreClick = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })
    window.open('https://play.google.com/store/apps/details?id=com.vermutapp.mobile')
  }

  const handleAppStoreClick = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })
    window.open('https://apps.apple.com/us/app/vermut/id1591939009')
  }

  const isMobile = checkMobile()

  return (
    <div className="fixed inset-0 overflow-x-auto align-middle shadow-xl transition-all md:flex md:items-center md:justify-center ">
      <Dialog.Panel className="scrollbar h-screen max-w-[750px] transform overflow-x-auto bg-white text-left align-middle shadow-xl transition-all md:h-auto md:rounded-2xl lg:overflow-hidden">
        <div className="relative flex flex-col items-center justify-center bg-[#E0EDEF] px-5 pb-8 pt-3">
          <ModalCloseBtn className="self-end" onClose={handleCloseModal} />
          <NextImage src={SuccessImage} width={168} height={122} alt="join-trial" />
        </div>
        <div className="flex flex-1 flex-col bg-white px-4 pb-8 pt-4 lg:px-8">
          <p className="font-roboto text-text-800 mb-4 text-center text-3xl font-medium">
            {t(`Enjoy this functionality and many more in the app completely free!`)}
          </p>

          <p className="font-roboto text-text-800 mb-4 text-center text-lg">
            {t(
              `Connect with people with the same interests, find activities on the calendar, create Meetups with your friends, join chat groups, manage your reservations and much more`,
            )}
          </p>

          {isMobile ? (
            <div className="flex flex-col justify-between md:flex-row">
              <Button
                variant="primary"
                // size="md"
                className="justify-center px-0 lg:px-2"
                leftIcon={() => (
                  <NextImage src={PlayStoreIcon} width={24} height={24} alt="play-store-icon" className="mr-2" />
                )}
                onClick={handlePlayStoreClick}>
                {t('Download on Google Play')}
              </Button>
              <div className="h-5 w-5" />
              <Button
                variant="primary"
                // size="md"
                className="justify-center px-0 lg:px-2"
                leftIcon={() => (
                  <NextImage src={AppStoreIcon} width={24} height={24} alt="app-store-icon" className="mr-2" />
                )}
                onClick={handleAppStoreClick}>
                {t('Download on App Store')}
              </Button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-between">
              <NextImage src={DownloadApppQrImage} width={224} height={224} alt="app-store-icon" className="mr-2" />
            </div>
          )}

          <div className="mt-4 flex items-center justify-center">
            <WebLink href={`/${lang}/contact`} locale={lang}>
              <span className="font-roboto text-secondary-400 text-lg font-medium underline">
                {' '}
                {t(`You have doubts? contact us`)}
              </span>
            </WebLink>
          </div>
        </div>
      </Dialog.Panel>
    </div>
  )
}

const FreeSubscriptionActivitiesModal = ({ meta, handleCloseModal }: FreeSubscriptionActivities) => {
  const { t } = useTranslation()
  const { markNotificationAsRead } = useUser()
  const tracking = useTracking()

  const count = (meta.freeSubscriptionActivitiesBalance || 5) as unknown as number

  React.useEffect(() => {
    // if (meta.id) {
    //   // debugger
    //   markNotificationAsRead(meta.id)
    // }
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-x-auto align-middle shadow-xl transition-all">
      <div className="px-4 md:px-0">
        <DialogPanel className="bg-background-10  max-w-[475px] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
          <div className="relative min-h-[299px]">
            <ModalCloseBtn className="absolute right-2 top-2 z-10 self-end" onClose={handleCloseModal} />
            <NextImage src={FreeSubscriptionActivitiesImage} fill alt="free subscription activities image" />
          </div>
          <div className="bg-white px-8 pb-8 pt-4">
            <p className="font-roboto text-text-800 mb-4 text-center text-[28px] font-medium leading-8">
              {t(`fsa_modal_title`, { count: count })}
            </p>

            <p className="font-roboto text-text-800 mb-4 text-center text-lg">{t(`fsa_modal_para`)}</p>

            <div className="flex flex-row justify-center md:flex-row">
              {/* <button
                // disabled={isBusy}
                className="bg-primary-800 from-primary-400 hover:bg-primary-900 w-full rounded-2xl bg-gradient-to-b px-6 py-4 text-center text-2xl font-semibold tracking-widest text-white shadow-lg transition-colors duration-300 disabled:opacity-50"
                onClick={() => handleCloseModal()}>

              </button> */}
              <Button
                variant="primary"
                size="base"
                className="w-full min-w-[264px] items-center justify-center lg:w-auto lg:self-center"
                onClick={() => handleCloseModal()}>
                {t('three_free_invite_credits_modal_parimary_cta')}
              </Button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </div>
  )
}

function ImageModal(props: ImageModalProps) {
  const { type, handleCloseModal } = props

  return (
    <Dialog as="div" className="relative z-50" open={true} onClose={() => handleCloseModal()}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {type === 'trial-required' && (
        <TrialRequiredModal type={type} meta={props.meta} handleCloseModal={handleCloseModal} />
      )}
      {type === 'out-credits-generic-pop-up' && (
        <OutCreditsGenericPopUp type={type} handleCloseModal={handleCloseModal} />
      )}

      {type === '3-free-invite-credits' && (
        <ThreeFreeInviteCreditsModal type={type} meta={props.meta} handleCloseModal={handleCloseModal} />
      )}
      {type === 'download-app-pop-up' && <DownloadAppModal type={type} handleCloseModal={handleCloseModal} />}

      {type === 'free-subscription-activities' && (
        <FreeSubscriptionActivitiesModal type={type} meta={props.meta} handleCloseModal={handleCloseModal} />
      )}
    </Dialog>
  )
}

export default withNoSSR(ImageModal) as typeof ImageModal
